import React, { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import "../../Assets/css/home.css";
import { isMobile, isMobileOnly } from 'react-device-detect';
import { Button } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { base_url, postHeader } from '../../../../Helpers/request';
import { useState } from 'react';
import { toast } from 'react-toastify';

import Banner1 from '../Assets/images/3.jpg'
import Banner2 from '../Assets/images/2.jpg'
import Banner01 from '../Assets/images/3-mob.jpg'
import Banner02 from '../Assets/images/2-mob.jpg'

const BannerSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const history = useHistory();
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    //-----------------------------------Banner
    axios.get(base_url + `/home/slider`, {
      headers: postHeader
    })
      .then(response => {
        if (response.data.status == 1) {
          setBanner(response.data.data);
          localStorage.setItem("bw-bannerdata", JSON.stringify(response.data.data));
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])
  return (
    <>
      <div className='declement_home_banner'>
      
        {/* // for mobile view */}
      <Slider {...settings} className='desktop-hide'>
        {/* {banner?.map((val, index) =>
        <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? val?.mobile_image : val?.image})` }}>
          <div className="rcs_hero_img">
            <Container fluid>
              <Row className='m-auto w-100'>
                <Col md={9} className='m-auto'>
                  <div className={isMobile ? 'text-center' : val.text_direction_class}>
                    <h1 data-aos="fade-up-left" data-aos-duration="700">{(val.title)}</h1>
                    <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">{(val.short_text)}</p>
                    <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/' + val.btn_link) }} variant="outline-dark">{val.btn_text}</Button>
                  </div>                  
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )} */}
        <div>
          <div className="rcs_hero_slider" style={{ backgroundImage: `url(${Banner01})` }}>
            <div className="rcs_hero_img">
              <Container fluid>
                <Row className='m-auto w-100'>
                  <Col md={10} className='m-auto'>
                    <div className='text-right'>
                      <h1 data-aos="fade-up-left" data-aos-duration="700">Lab-Grown Diamonds</h1>
                      <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">Ethical Lab Diamonds with the finest Craftsmanship</p>
                      <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" variant="outline-dark"><Link to='/diamonds/lab'>Shop Lab-grown Diamonds</Link></Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <div>
          <div className="rcs_hero_slider" style={{ backgroundImage: `url(${Banner02})` }}>
            <div className="rcs_hero_img">
              <Container fluid>
                <Row className='m-auto w-100'>
                  <Col md={10} className='m-auto'>
                    <div className='text-right'>
                      <h1 data-aos="fade-up-left" data-aos-duration="700">Perfect Symbol Of Love</h1>
                      <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">Explore the latest Wedding collection</p>
                      <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" variant="outline-dark"><Link to='/wedding-bands'>Shop Wedding Bands</Link></Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </Slider>
      
        {/* // for desktop view */}
        <Slider {...settings} className='mobile-hide'>
        {/* {banner?.map((val, index) =>
        <div className="rcs_hero_slider" style={{ backgroundImage: `url(${isMobileOnly ? val?.mobile_image : val?.image})` }}>
          <div className="rcs_hero_img">
            <Container fluid>
              <Row className='m-auto w-100'>
                <Col md={9} className='m-auto'>
                  <div className={isMobile ? 'text-center' : val.text_direction_class}>
                    <h1 data-aos="fade-up-left" data-aos-duration="700">{(val.title)}</h1>
                    <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">{(val.short_text)}</p>
                    <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" onClick={() => { history.push('/' + val.btn_link) }} variant="outline-dark">{val.btn_text}</Button>
                  </div>                  
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )} */}
        <div>
          <div className="rcs_hero_slider" style={{ backgroundImage: `url(${Banner1})` }}>
            <div className="rcs_hero_img">
              <Container fluid>
                <Row className='m-auto w-100'>
                  <Col md={10} className='m-auto'>
                    <div className='text-right'>
                      <h1 data-aos="fade-up-left" data-aos-duration="700">Lab-Grown Diamonds</h1>
                      <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">Ethical Lab Diamonds with the finest Craftsmanship</p>
                      <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" variant="outline-dark"><Link to='/diamonds/lab'>Shop Lab-grown Diamonds</Link></Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <div>
          <div className="rcs_hero_slider" style={{ backgroundImage: `url(${Banner2})` }}>
            <div className="rcs_hero_img">
              <Container fluid>
                <Row className='m-auto w-100'>
                  <Col md={10} className='m-auto'>
                    <div className='text-right'>
                      <h1 data-aos="fade-up-left" data-aos-duration="700">Perfect Symbol Of Love</h1>
                      <p data-aos="fade-up-left" data-aos-duration="1200" data-aos-delay="300">Explore the latest Wedding collection</p>
                      <Button data-aos="fade-up-left" data-aos-duration="1800" data-aos-delay="400" variant="outline-dark"><Link to='/wedding-bands'>Shop Wedding Bands</Link></Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </Slider>
      
      </div>
    </>
  )
}

export default BannerSlider;