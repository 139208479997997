import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import returns from '../Assets/images/returns.png'
import made_in_canada from '../Assets/images/made_in-canada.png'
import shipping from '../Assets/images/shipping.png'
import { Link } from 'react-router-dom'
import engagement_rings from '../Assets/images/engagement_rings.jpg'
import wedding_rings from '../Assets/images/wedding_rings.jpg'
const Shop_by_category = () => {
  return (
    <section className='declement_shop_by_category'>
        <div className='feature'>
        <Container>
            <Row>
                <Col xs={12} lg={4} md={4}>
                    <div className='feature_box'>
                        <img src={returns} alt='declement'/>
                        <span>15-Day Free Returns</span>
                    </div>
                </Col>
                <Col xs={12} lg={4} md={4}>
                    <div className='feature_box'>
                        <img src={made_in_canada} alt='Made In Canada'/>
                        <span>Made In Canada</span>
                    </div>
                </Col>
                <Col xs={12} lg={4} md={4}>
                    <div className='feature_box'>
                        <img src={shipping} alt='Free Shipping For USA And Canada'/>
                        <span>Free Shipping For USA And Canada</span>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>

        <div className='declement_shop_by_category_innerbox'>
            <h1 className='dc_title text-center'>Shop By Category</h1>
            <Container>
                <Row>
                    <Col lg={6} md={6}>
                    <div className='contant-box'>
                        <Link to='/engagement-rings'>
                        <div className='img-box'>
                        <img src={engagement_rings}  alt='Engagement Rings'/>
                        </div>
                        </Link>
                        <Link to='/engagement-rings'>Engagement Rings</Link>
                    </div>
                    </Col>
                    <Col lg={6} md={6}>
                    <div className='contant-box'>
                        <Link to='/wedding-bands'>
                        <div className='img-box'>
                        <img src={wedding_rings}  alt='Wedding Bands'/>
                        </div>
                        </Link>
                        <Link to='/wedding-bands'>Wedding Bands</Link>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </section>
  )
}

export default Shop_by_category