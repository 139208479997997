import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Assets/CSS/home.css";
import { Helmet } from 'react-helmet';
import BannerSlider from './Bannerslider';

import Insta from './insta';
// import ExploreCollection from './ExploreCollection';
// import Virtualappointment from './VirtualAppointment';
// import Customer from './Customer';
import ShopDiamondShape from "./ShopDiamondShape";
// import Gemstones from "./GemstoneShop";
import RingStyle from "./RingStyle";
// import Ringbuilderhome from "./Ringbuilderhome";
// import SignupTo from "./SignupTo";
import AOS from 'aos';
import 'aos/dist/aos.css' ;
import { useSelector } from 'react-redux';
import Shop_by_category from "./Shop_by_category";
import Creact_own_ring from "./Creact_own_ring";
import Fine_jewelry from "./Fine_jewelry";

const Home = () => {
  const metaDetails = useSelector(state => state.persistedReducer.meta.meta)

  useEffect(() => {
    AOS.init({
      duration : 1000,
      // disable: 'mobile'
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails.title} />
        <meta property="og:description" content={metaDetails.description} />
        <meta property="og:url" content={metaDetails.url} />
        <meta property="og:site_name" content="Belgium Webnet" />
      </Helmet>

      <BannerSlider />
      <Shop_by_category/>
      <ShopDiamondShape />
      <Creact_own_ring/>
      <RingStyle />
      <Fine_jewelry/>
      <Insta />


      {/*             
      <ExploreCollection />
      <Ringbuilderhome />
            
      <Gemstones />
      <Virtualappointment/>      
      <Customer />
      
      <SignupTo /> */}

    </>
  )
}

export default Home;