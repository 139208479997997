import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@mui/material';

const Creact_own_ring = () => {
    // const history = useHistory();
    return (
        <section className='dc_create_your_own_ring'>
            <Container>
                <Row>
                    <Col lg={5}>
                        <h3>CREATE YOUR OWN</h3>
                        <h1>ENGAGEMENT RING</h1>
                        <p>Bringing to life your dream engagement ring is easy. Start by choosing either the perfect ring setting or the diamond that reflects your love.</p>
                        <p>It’s really that simple!</p>
                        <Link variant="contained" to='/ringsettings' className='dc_create_your_own_ring_btn br-0'>start with a setting</Link>
                        <Link variant="contained" to='/diamonds' className='dc_create_your_own_ring_btn'>start with a diamond</Link>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Creact_own_ring