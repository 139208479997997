import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom';
import certificate from '../Assets/images/DeClementAssocciation.png'
import lab_grown_img from '../Assets/images/lg-img.jpg'

import virtual_appointment_img from '../Assets/images/virtual-appont.jpg'

const Fine_jewelry = () => {
    const history = useHistory();
    return (
        <>
            <section className='dc_fine_jewelry mb-5'>
                <Container>
                    <Row>
                        <Col lg={5}>
                            <h1>FINE JEWELRY</h1>
                            <p>We have a breathtaking collection of diamond fine jewelry.</p>
                            <Link variant="contained" to='/fine-jewelry' className='dc_fine_jewelry_btn'>Shop Jewelry</Link>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='BADGE-OF-HONOR'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={5}>
                            <h1>BADGE OF HONOR</h1>
                            <p>De Clement is a certified jeweler under the Canadian Jewellers Association, and with that title, on hand, we bring you the best of lab diamonds at excellent prices.</p>
                            {/* <Link variant="contained" onClick={() => history.push('/jewelry/fine-jewelry')} className='dc_fine_jewelry_btn br-0'>Shop Jewelry</Link> */}
                        </Col>
                        <Col lg={7} className='text-right'>
                            <img src={certificate} alt="BADGE OF HONOR" className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='labgrown_diamond'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <img src={lab_grown_img} alt="LAB GROWN DIAMONDS" className='img-fluid pr-5' />
                        </Col>
                        <Col lg={6}>
                            <h1>MADE TO PERFECTION
                                LAB GROWN DIAMONDS, ENABLED BY TECHNOLOGY</h1>
                            <p>We love having control over how our diamonds are created, where diamonds are cultivated from our state-of-the-art laboratory by adhering to the highest standard.</p>
                            <Link variant="contained" to='/diamonds/lab' className='labgrown_diamond_btn'>Shop Lab-grown Diamonds</Link>
                        </Col>

                    </Row>
                </Container>
            </section>

            <section className='dc_engagement_ring'>
                <Container>
                    <Row>
                        <Col lg={10} className='p-0'>
                            <h1>SPARKLE BRED FROM IMPECCABLE CRAFTSMANSHIP</h1>
                            <p>We are proud to offer the finest craftmanship to complement the De Clement Diamonds!</p>
                            <Link variant="contained" to='/engagement-rings' className='dc_engagement_ring_btn mt-2'>Shop Engagement Rings</Link>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='labgrown_diamond dc_virtual_appointment'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <img src={virtual_appointment_img} alt="LAB GROWN DIAMONDS" className='img-fluid pr-5' />
                        </Col>
                        <Col lg={6} className='p-0'>
                            <h1>OUR JEWELRY SPECIALISTS ARE HERE FOR YOU!</h1>
                            <p>Your virtual jewelry guide to finding the perfect piece of jewelry.</p>
                            <Link variant="contained" to='/make-an-appointment' className='labgrown_diamond_btn'>Book A Virtual Appointment</Link>
                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Fine_jewelry