import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import round from "../../../../Assets/images/home/diamond-round.png";
import RoundShape from "../../../../Assets/images/diamond-shape/Diamond_Round.svg";
import princess from "../../../../Assets/images/diamond-shape/princess.svg";
import cushion from "../../../../Assets/images/diamond-shape/cushion.svg";
import emerald from "../../../../Assets/images/diamond-shape/emerald.svg";
import oval from "../../../../Assets/images/diamond-shape/oval.svg";
import radiant from "../../../../Assets/images/diamond-shape/radiant.svg";
import asscher from "../../../../Assets/images/diamond-shape/asscher.svg";
import marquise from "../../../../Assets/images/diamond-shape/marquise.svg";
import heart from "../../../../Assets/images/diamond-shape/heart.svg";
import pear from "../../../../Assets/images/diamond-shape/pear.svg";

import roundImg from "../../../../Assets/images/home/diamond-shapes-img/Round.webp";
import princessImg from "../../../../Assets/images/home/diamond-shapes-img/Princess.webp";
import cushionImg from "../../../../Assets/images/home/diamond-shapes-img/Cushion.webp";
import emeraldImg from "../../../../Assets/images/home/diamond-shapes-img/Emerald.webp";
import ovalImg from "../../../../Assets/images/home/diamond-shapes-img/Oval.webp";
import radiantImg from "../../../../Assets/images/home/diamond-shapes-img/Radiant.webp";
import asscherImg from "../../../../Assets/images/home/diamond-shapes-img/Asscher.webp";
import marquiseImg from "../../../../Assets/images/home/diamond-shapes-img/Marquise.webp";
import heartImg from "../../../../Assets/images/home/diamond-shapes-img/Heart.webp";
import pearImg from "../../../../Assets/images/home/diamond-shapes-img/Pear.webp";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useHistory } from 'react-router-dom';
function ShopDiamondShape() {
var history = useHistory();
    var settings2 = {
        dots: false,
        accessibility: true,
        arrows: true,
        slidesToShow: 10,
        slidesToScroll: 0,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };
   const shopbyshape = (value) =>{
    var data1 = {
        shape: value,
    } 
    sessionStorage.setItem("bw_d_filter", JSON.stringify(data1));
    history.push('/diamonds');
    window.scrollTo(0, 0);
    }

    return (
        <>
            <section className="dc_shop_by_shap rcs_ring_style_section mt-4 ">
                <Container className="rcs_custom_home_container">
                <h1 className='dc_title text-center'>shop by shape</h1>
                    <Row>
                        <Col lg={12} className="p-0">
                            <div className="rcs_customer_review_slider" data-aos="fade-up">
                                <Slider className="rcs_customer_say_inner rcs_shop_diamond_slider" {...settings2}  >

                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img" onClick={()=> shopbyshape('Round')}>
                                            <div className="rcs_shop_by_diamond_box_img_size">
                                                <Image className="rcs_shop_by_diamond_box_img_hover" src={roundImg} alt="Round" />
                                            </div>
                                            <h3> Round </h3>
                                        </div>
                                        
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img" onClick={()=> shopbyshape('Princess')}>
                                            <div className="rcs_shop_by_diamond_box_img_size">
                                                <Image className="rcs_shop_by_diamond_box_img_hover" src={princessImg} alt="Princess"/>
                                            </div>
                                            <h3 >  Princess  </h3>
                                        </div>
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img" onClick={()=> shopbyshape('Cushion')}>
                                            <div className="rcs_shop_by_diamond_box_img_size">
                                                <Image className="rcs_shop_by_diamond_box_img_hover" src={cushionImg} alt="Cushion"/>
                                            </div>
                                            <h3 >  Cushion  </h3>
                                        </div>
                                        
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img" onClick={()=> shopbyshape('Emerald')}>
                                            <div className="rcs_shop_by_diamond_box_img_size">
                                                <Image className="rcs_shop_by_diamond_box_img_hover" src={emeraldImg} alt="Emerald"/>
                                            </div>
                                            <h3 >  Emerald </h3>
                                        </div>
                                        
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img" onClick={()=> shopbyshape('Oval')}>
                                            <div className="rcs_shop_by_diamond_box_img_size">
                                                <Image className="rcs_shop_by_diamond_box_img_hover" src={ovalImg} alt="Oval"/>
                                            </div>
                                            <h3 > Oval  </h3>
                                        </div>
                                        
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img" onClick={()=> shopbyshape('Radiant')}>
                                            <div className="rcs_shop_by_diamond_box_img_size">
                                                <Image className="rcs_shop_by_diamond_box_img_hover" src={radiantImg} alt="Radiant"/>
                                            </div>
                                            <h3 >  Radiant  </h3>
                                        </div>
                                       
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img" onClick={()=> shopbyshape('Asscher')}>
                                            <div className="rcs_shop_by_diamond_box_img_size">
                                                <Image className="rcs_shop_by_diamond_box_img_hover" src={asscherImg} alt="Asscher"/>
                                            </div>
                                            <h3 >   Asscher   </h3>
                                        </div>
                                        
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img" onClick={()=> shopbyshape('Marquise')}>
                                            <div className="rcs_shop_by_diamond_box_img_size">
                                                <Image className="rcs_shop_by_diamond_box_img_hover" src={marquiseImg} alt="Marquise"/>
                                            </div>
                                            <h3 >  Marquise  </h3>
                                        </div>
                                       
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img" onClick={()=> shopbyshape('Heart')}>
                                            <div className="rcs_shop_by_diamond_box_img_size">
                                                <Image className="rcs_shop_by_diamond_box_img_hover" src={heartImg} alt="Heart"/>
                                            </div>
                                            <h3 >  Heart  </h3>
                                        </div>
                                       
                                    </div>
                                    <div className="rcs_shop_by_diamond_box_main">
                                        <div className="rcs_shop_by_diamond_box_img" onClick={()=> shopbyshape('Pear')}>
                                            <div className="rcs_shop_by_diamond_box_img_size">
                                                <Image className="rcs_shop_by_diamond_box_img_hover" src={pearImg} alt="Heart"/>
                                            </div>
                                            <h3 >  Pear  </h3>
                                        </div>
                                        
                                    </div>
                                    
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}


export default ShopDiamondShape;
