import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import solitaire from "../Assets/images/style/solitaire_1.png"
import pave from "../Assets/images/style/pave_2.png"
import three_stome from "../Assets/images/style/three-stome_3.png"
import halo from "../Assets/images/style/halo_4.png"
import vintage from "../Assets/images/style/vintage_5.png"
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";

const RingStyle = () => {
    var history = useHistory();

    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    var settingsV2 = {
        dots: false,
        arrows: true,
        centerMode: true,
        infinite: true,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows:false,
                }
            }
        ]
    };

    const shopbystyle = (value) => {
        var data1 = {
            style: value,
        }
        sessionStorage.setItem("bw_s_filter", JSON.stringify(data1));
        history.push('/ringsettings');
    }
    return (
        <>
            <Container className='dc_shop_by_style rcs_custom_home_container' >
            <h1 className='dc_title text-center'>Shop By Style</h1>
                <div className='rcs_ringstyle rcs_ringstyle_v2' >
                    <Slider {...settingsV2}>
                        <div className='rcs_ringstyle_content_box '>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage src={solitaire} alt='Solitaire' onClick={() => shopbystyle('Solitaire')}/>
                            </div>
                            <h2 onClick={() => shopbystyle('Solitaire')}>Solitaire</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Pave')} src={pave} alt='Pave'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Pave')}>Pave</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Halo')} src={halo} alt='Halo'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Halo')}>Halo</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Three stone')} src={three_stome} alt='Three Stone'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Three stone')}>Three Stone</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Vintage')} src={vintage} alt='Vintage'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Vintage')}>Vintage</h2>
                        </div>

                        <div className='rcs_ringstyle_content_box '>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage src={solitaire} alt='Solitaire' onClick={() => shopbystyle('Solitaire')}/>
                            </div>
                            <h2 onClick={() => shopbystyle('Solitaire')}>Solitaire</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Pave')} src={pave} alt='Pave'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Pave')}>Pave</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Halo')} src={halo} alt='Halo'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Halo')}>Halo</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Three stone')} src={three_stome} alt='Three Stone'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Three stone')}>Three Stone</h2>
                        </div>
                        <div className='rcs_ringstyle_content_box'>
                            <div className='rcs_ringstyle_content_box_img'>
                                <LazyLoadImage onClick={() => shopbystyle('Vintage')} src={vintage} alt='Vintage'/>
                            </div>
                            <h2 onClick={() => shopbystyle('Vintage')}>Vintage</h2>
                        </div>
                    </Slider>

                </div>


            </Container>
        </>
    );
}

export default RingStyle;