import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import "../../Assets/css/home.css";

const Insta = () => {
    return (
        <>
            <h1 className='dc_title text-center'>View Our Instagram</h1>
            <h5 class="dc_subtitle text-center">Follow <a href="https://www.instagram.com/declementofficial/" target="_blank"> @declement</a> on Instagram to get inspired</h5>
            {/* <div className="elfsight-app-368122dd-eb07-4a5c-b0a6-014ed3804162"></div> */}
            <iframe src="https://instagram.demobw.live/de-clement/" frameborder="0" width="100%" class="insta-iframe"></iframe>
        </>
    )
}

export default Insta;